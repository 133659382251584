import React, { Component } from 'react';
//import * as API from "../api/ticketsAPI";
//import * as tConst from "./Constants";
//import { Redirect } from "react-router-dom";

class TriagePage extends Component {
  state = {
  };
    render() {
        return (
            <div>
                
            </div>
        );
    }
  render() {
//    const { auth } = this.props;
//    if (auth !== null && auth.un !== "" && auth.pw !== "") {
      return (
        <>
            <div><h1>Hello Triage</h1></div>
        </>
      );
//    } else {
//      return <Redirect to="/login" />;
//    }
  }
}

export default TriagePage;